// Enable data zoom when user click bar.
export const zoomBarData = function() {
    // var data = [
    //   220,
    //   182,
    //   191,
    //   234,
    //   290,
    //   330,
    //   310,
    //   123,
    //   442,
    //   321,
    //   90,
    //   149,
    //   210,
    //   122,
    //   133,
    //   334,
    //   198,
    //   123,
    //   125,
    //   220
    // ];
    // var yMax = 500;
    // var dataShadow = [];
  
    // for (var i = 0; i < data.length; i++) {
    //   dataShadow.push(yMax);
    // }
    // // Enable data zoom when user click bar.
    // var zoomSize = 6;
    // myChart.on("click", function(params) {
    //   console.log(dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)]);
    //   myChart.dispatchAction({
    //     type: "dataZoom",
    //     startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
    //     endValue:
    //       dataAxis[Math.min(params.dataIndex + zoomSize / 2, data.length - 1)]
    //   });
    // });
  };
  