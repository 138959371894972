<template>
    <div>
        <b-modal
            v-model="showModalCambioContrasena"
            id="modal-cambio-contrasena"
            title="Cambiar Contraseña" size="md"
            hide-footer
            no-close-on-backdrop
            no-close-on-esc hide-header-close
        >
            <div class="modal-body">
                <div>
                    <div class="form-row">
                        <div class="col-sm-12">
                            <div class="form-group row">
                                <label for="nombre" class="col-sm-2 col-form-label text-right">
                                    Password:
                                </label>
                                <div class="col-sm-9">
                                    <input type="password" class="form-control" v-model="password">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="text-right">
                    <button class="btn btn-success" style="margin-left:10px;" @click="cambiarPassword()">
                        Guardar
                    </button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import axios from '@/modules/common/axiosFacturacion';
import { mapMutations, mapState } from 'vuex'
import { util } from '@/plugins/util';

export default {
    name: "theModalCambioContrasena",
    components: {},
    data() {
        return {
            password: null,
        }
    },
    methods: {
        ...mapMutations('main', [
            "setShowModalCambioContrasena",
        ]),
        async cambiarPassword() {
            try {
                const params = {
                    password: this.password,
                };
                const RESPONSE = await axios.post(`/facturacion/usuario/${this.user.id}/password`, params);
                this.password = null;
                this.setShowModalCambioContrasena(false);
                util.showNotify(RESPONSE.data.message, 'success');
            } catch (error) {
                const FALLO_VALIDACION = 422;
                if (error.response.status !== FALLO_VALIDACION) {
                    util.showNotify(error.response.data.message, 'error');
                } else {
                    Object.entries(error.response.data.data).forEach(([, mensajes]) => {
                        mensajes.forEach((texto) => util.showNotify(texto, 'error'));
                    });
                }
            }
        },
    },
    computed: {
        ...mapState('main', [
            "showModalCambioContrasena",
            "user",
        ]),
    },
    watch: {},
}
</script>